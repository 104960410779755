*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Blog */
.blog {
  color: #a2a2c7;
  font-size: 60px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

/* Stay up to date with current initiatives */
.stay-updated {
  color: white;
  font-size: 64px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 72px;
  word-wrap: break-word;
}

/* READ MORE */
.read-more {
  color: black;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1.6px;
  word-wrap: break-word;
}

/* Services */
.services,
.blogx {
  color: #ded8d8;
  font-size: 30px;
  font-family: "Roboto";
  font-weight: 700;
  letter-spacing: 3px;
  word-wrap: break-word;
}

/* Community Minded Consulting Firm */
.community {
  color: white;
  font-size: 64px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 72px;
  word-wrap: break-word;
}

/* E3 Strategic Solutions empowers organizations to reach their full potential with professional planning services */
.empowers-organizations {
  color: white;
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 32px;
  word-wrap: break-word;
}

/* ABOUT US */
.about-us {
  color: white;
  font-size: 16px;
  font-family: "roboto";
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1.6px;
  word-wrap: break-word;
}

/* Book Now */
.book-now {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 4px;
  word-wrap: break-word;
}

/* Terms of Use */
.terms-of-use {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* Privacy */
.privacy {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* Contact */
.contact {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* E3 Strategic Solutions */
.e3-strategic-solutions {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* Web Design: PM Daybreak Designs */
.web-design {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* About Us */
.about-us-secondary {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  word-wrap: break-word;
}

/* Services (Secondary) */
.services-secondary {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  word-wrap: break-word;
}

/* Blog (Secondary) */
.blog-secondary {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  word-wrap: break-word;
}

/* Contact (Secondary) */
.contact-secondary {
  color: white;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  word-wrap: break-word;
}

.Title {
  text-shadow: 0px 4px 3px rgba(162, 158, 158, 0.8);
}

.Title2 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.8);
}

.ButtonAbout:hover,
.ButtonServices:hover,
.ButtonBlog:hover {
  transform: scale(1.2);
  transition: transform ease-in-out 0.3s;
  cursor: pointer;
}

.ListitemDesktopNa,
.Logos,
.ListitemDesktopNaActive {
  cursor: pointer;
  transition: background-color 0.3s ease-in;
}

.ListitemDesktopNa:hover {
  background-color: white !important;
}

.ListitemDesktopNa:hover .Label {
  color: black !important;
}

.Button:hover {
  transform: scale(1.1);
  transition: transform ease-in-out 0.5s;
  background-color: white !important;
  cursor: pointer;
}

.Button:hover .Label {
  color: black !important;
}

.Button:hover .ReadMore {
  color: black !important;
}

.Button {
  transition: transform ease-in-out 0.5s;
  z-index: 4;
  padding: 8px 20px; /* Adjust padding for the button */
  /* background: #784F39; OLD COLOR SCHEME*/
  background: rgba(33, 74, 109, 1);
  box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px; /* Adjust border radius for a less oval shape */
  border: 2px black solid;
  display: inline-flex;
  align-items: center;
  font-family: PlayfairDisplay;
}

.Label {
  color: white;
  font-size: clamp(16px, 2vw, 16px); /* Adjust font size */
  font-family: "Roboto";
  font-weight: 700;
  letter-spacing: 1.6px;
  word-wrap: break-word;
  cursor: pointer;
}

.Content1 > .SectionTitle,
.Content1 > .SectionTitle > .ContentX {
  height: auto !important;
  min-height: 180px;
}

@media (max-height: 500px) and (max-width: 950px) {
  .InitialAbout {
    display: none;
  }
  .Lead {
    font-size: 15px !important;
  }
  .Row {
    display: block !important;
  }
  .Servicesdesktop {
    top: 10vh !important;
  }
}

.Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center !important; 
  align-items: center; /*added  changed*/
  gap: 20px; /* Adjust the gap as needed */
  width: 90vw;
  margin-bottom: 40px;
}

.Video {
  width: calc(
    50% - 20px
  ); /* Adjust the width and gap to fit two videos per row */
  margin-bottom: 20px; /* Adjust the margin as needed */
}

@media screen and (max-width: 768px) {
  .Video {
    width: calc(100% - 20px); /* Adjust the width and gap for mobile */
  }
}

.ContentX {
  align-self: "stretch";
  flex-direction: "column";
  justify-content: "flex-start";
  align-items: "flex-start";
  display: "flex";
}

@media (max-height: 500px) and (max-width: 916px) {
  .Row {
    display: block !important;
  }
  .MySkill {
    top: 200vh !important;
    margin-top: 70px;
  }
  .Content1 {
    max-height: 200px;
    max-width: 500px;
  }
  .Card {
    max-height: 600px;
    max-width: 500px;
    margin-bottom: 70px;
  }
  .Row.Card {
    max-width: "200px" !important;
  }
  .Row.Content1 {
    max-width: "200px" !important;
  }
}

@media (max-width: 299px) {
  .Card1 {
    min-height: 880px !important; 
    /* max-height:760px !important; */
    
  }
}

@media (min-width:300px) and (max-width: 400px) {
  .Content1 {
    max-width: "25vw" !important;
  
  }
  .Card {
    max-width: "25vw" !important;
  }
  .Card1 {
    height: fit-content !important;
  }
}

@media (min-width: 401px) and (max-width: 716px) {
  .Content1 {
    max-width: "25vw" !important;
  }
  .Card {
    max-width: "35vw" !important;
  }
  .Card1 {
    min-height: 670px !important;
    
    max-height:760px !important;
  }
}

@media (min-width: 717px) and (max-width: 865px) {
  .Content1 {
    max-width: "25vw" !important;
  }
  .Card {
    max-width: "25vw" !important;
  }
  .Card1 {
    /* height: fit-content !important; */
    min-height: 730px !important;
    max-height:740px !important;
  }
}

@media (min-width: 868px) and (max-width: 916px) {
  .Content1 {
    max-width: "25vw" !important;
  }
  .Card {
    max-width: "25vw" !important;
  }
  .Card1 {
    min-height: 720px !important;
    max-height: 800px !important;
  }
}

@media (min-width: 916px) and (max-width: 1099px) {
  .Content1 {
    max-width: "25vw" !important;
  }
  .Card {
    max-width: "25vw" !important;
    min-width:'40%' !important;
  }
  .Card1 {
    min-height: 660px !important; 
    max-height:680px !important;
  }
}

@media (min-width: 1100px) and (max-width: 1238px) {
  .Content1 {
    max-width: "25vw" !important;
  }
  .Card {
    max-width: "25vw" !important;
    min-width:'40%' !important;
  }
  .Card1 {
    max-width: 45vw !important;
    min-width: 44vw !important;
    min-height: 400px !important;
    /* 800 */
    max-height:660px !important;
  }
}

@media (min-width: 1239px) and (max-width: 1438px) {
  .Content1 {
    max-width: "25vw" !important;
  }
  .Card {
    max-width: "25vw" !important;
    min-width:'40%' !important;
  }
  .Card1 {
    min-height: 600px !important; 
    max-height:660px !important;
 
    /* //100 */
  }
}

@media (min-width: 1439px) and (max-height:1719px){
  .Content1 {
    max-width: "22vw" !important;
  }
  .Card {
    max-width: "22vw" !important;
    width: 100%;
    min-width:'40%' !important;
  }
  .Card1 {
    min-height: 150px !important;
    min-width: 25vh !important;
    max-width: 50vh !important;
    max-height:860px !important;
  }
}

@media (min-width: 1720px) and (max-height:1899px){
  .Content1 {
    max-width: "25vw" !important;
  }
  .Card {
    max-width: "25vw" !important;
    min-width:'40%' !important;
  }
  .Card1 {
    min-height: 500px !important;
    max-height:780px !important;
  }
}

.Blog {
  max-width: 100%;
  height: auto;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3vh;
  display: inline-flex;
  padding: "0px 3rem";
}

/*SERVICES PAGE*/

/* Servicecards1 */
.Servicecards1 {
  display: flex;
  justify-content: space-around;
  /* Adjust spacing on the sides */
}

.Frame2230 {
  display: flex;
  gap: 2vw; /* Reduce space between cards */
}

.Servicecard {
  border: solid #5f5fb0;
  width: 20vw;
  max-width: 300px; /* Maximum width for each card */
  height: 350px; /* Increased height for taller cards */
  position: relative;
  box-shadow: 5px 5px 60px -20px #5f5fb0;
  border-radius: 30px;
  overflow: hidden; /* Ensure content doesn't overflow */
}

@media (max-height: 500px) and (max-width: 950px) {
  .Servicecard {
    width: 40% !important;
    height: 300px !important;
  }
}

/* For mobile devices */
@media (max-width: 950px) {
  .Frame2230 {
    /* flex-wrap: wrap; */
    max-width: 100%;
    margin: 10px;
    justify-content: space-between;
  }

  .Frame1 {
    padding-bottom: 1rem;
  }
}

.CardImage {
  width: 100%;
}

.cardpic {
  width: 100%;
  height: auto;
  border-top-left-radius: 20px;
  object-fit: scale-down;
}
.cardpic:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
}

.CardTextx {
  width: 100%;
  color: black;
  font-family: PlayfairDisplay;
  font-weight: 400;
  box-sizing: border-box; /* Include padding in width calculation */
}

.Group5 {
  position: absolute;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  padding: 10px 0;
}

.CardTitle {
  display: flex;
  justify-content: center;
}

.TitleSer {
  color: black;
  font-family: "PlayfairDisplay", sans-serif;
  margin: 10px; /* Remove default margin */
}
@media (max-width: 575px) {
  .TitleSer {
    font-size: 30px !important;
    font-weight: bold !important;
  }
  .CardTextx {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
  }
}

@media (min-width: 576px) {
  .TitleSer {
    font-size: 15px !important;
    font-weight: 100 !important;
  }
  .CardTextx {
    font-size: 15px;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (min-width: 770px) {
  .TitleSer {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .CardTextx {
    font-size: 15px;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.Servicecard .Button {
  position: absolute;
  justify-content: center;
  bottom: 10px;
  width: 50%;
  text-align: center;
  margin-top: 2px; /* Adjust margin as needed */
  left: 50%;
  transform: translateX(-50%);
}

.ReadMore {
  color: white;
  font-size: clamp(14px, 2vw, 15px);
  font-family: Roboto, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 1px 2px; /* Adjust padding as needed */
  border-radius: 5px;
  cursor: pointer;
}

@media (min-width: 950px) {
  .BodyX {
    height: 944.38px;
  }
}

@media (max-width: 950px) {
  .BodyX {
    height: 644.38px;
  }
  .Servicesx {
    top: 115px !important;
  }
}

/*BLOG*/

.BlogX,
.ContactX {
  position: absolute;
  background: white;
}

.ComingSoon {
  font-family: "Roboto", sans-serif;
  color: black;
  /* Additional styles can be added as needed */
}

.ContactForm {
  background: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.FormGroup {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #5f5fb0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #3a3ac0;
}

.ContactForm {
  top: 3vh;
  position: relative;
}
@media (min-width: 576px) {
  .ContactForm {
    top: 2vh !important;
    position: relative;
  }
}

@media (max-width: 950px) {
  .ContactX {
    top: 0vh !important;
  }
}

@media (max-height: 400px) {
  .ContactX {
    height: 130vh !important;
  }
}

@media (max-width: 453px) {
  .contact-footer {
    display: none !important;
  }

  .Footerprivacy,
  .HeadingName {
    gap: 4px !important;
    justify-content: space-evenly !important;
    width: 100%;
  }
}

@media (max-width: 351px) {
  .HeadingName {
    .HeaderMenuDefault .Label,
    .MenuItemDefault .Label {
      font-size: 8px !important;
    }
  }
}
/* @media (min-width: 950px) {
  .ContactX .Frame1 {
    top: 100px;
  }

    .Footerprivacy {
    .HeaderMenuDefault {
      .Label, .Label > a {
        font-size: 8px !important;
      }
  
    }
  }
} */

@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1);
  }
  100% {
      transform: scale(1);
  }
}

.subscribe-button,.donate-button {
  animation: pulse 2s infinite;
}


.subscribe-button:hover,.donate-button:hover{
  transform: scale(1.1) !important;
  background-color:white !important;
  color:black !important;
}

/* #subscribe-form{
  margin-top: 110px;
}
@media(min-width:667px){
  #subscribe-form{
    margin-top: 140px !important;
  }
}
@media(min-width:500px) and (max-width:667px){
  #subscribe-form{
    margin-top: 210px !important;
  }
}
@media(max-width:500px){
  #subscribe-form{
    margin-top: 80px !important;
  }
  #subscribe-form p{
    font-size: 16px !important;
  }
} */

/*MODAL*/

.fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  -ms-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 2rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

@media(max-width:1100px){
  .modal-newsletter{
    max-width: 80vw !important;

  }
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  height: fit-content;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
}

@media (max-height:800px) {
  .modal-content {
    max-height: 100%;
  }
  #shipping-content {
    max-height: 80vh !important;
  }
  
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  /* This is for the MODAL BACKDROP for the SHOP PAGE */
  background-color: rgba(0, 0, 0, 0.7);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 1;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

/* .modal-body img {
  border-radius: 20px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
} */
.stripelogo{
  height: 40px;
  float: right;
  margin-top: 10px;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 994px) {
  .overlays-container-mobile {
    display: block !important;
  }

  #Homedesktop1,
  #Homedesktop2,
  #Homedesktop3 {
    display: none;
  }

  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .text-content3-mobile {
    transition: all 2s;
  }

  .AboutUs {
    display: none !important;
  }

  .AboutUs-mobile {
    display: block !important;

    .Place {
      margin-top: 1rem;
    }

    .Blog {
      top: 0 !important;
      position: relative !important;
      left: 0 !important;
      max-width: 100% !important;
      height: auto !important;
    }
  }

  .Services {
    display: none;
  }

  .Services-mobile {
    display: flex !important;

    .Servicesx {
      top: 20px !important;

      .Home {
        .CardTextz {
          padding-right: 0px !important;
        }
      }
    }

    .BodyX {
      .Servicecards1 {
        .Frame2230 {
          display: flex !important;
          flex-direction: column !important;
          gap: 40px !important;
          margin-top: 2rem;

          .Servicecard {
            max-width: 100%;
            width: 100%;
          }
        }

        .Frame2230 .Servicecard {
          display: flex !important;

          .cardpic {
            height: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          .CardTextx {
            margin-top: 50px;
          }
        }
      }
    }
  }
}
/* mine */
/* @media (max-width: 425px){ */
@media (max-width: 573px) {
  .Aboutus {
    .Text,
    .content-name,
    .E3StrategicSolutions {
      color: black !important;
      text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.8) !important;
    }
  }
}

@media (min-width: 412px) and (max-width: 915px) {
  .Footerdesktop {
    height: 50px !important;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.modal-open {
  overflow: hidden; /* Prevent scrolling when modal is open */
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  backdrop-filter: blur(5px); /* Add blur effect to the background */
  z-index: 7; /* Ensure the overlay is on top of other content */
}

.color-white {
  color: white;
}

/* Small devices such as large phones (640px and up) */
/* @media only screen and (min-width: 40em) {...} */

/* Medium devices such as tablets (768px and up) */
/* @media only screen and (min-width: 48em) {...} */

/* Large devices such as laptops (1024px and up) */
/* @media only screen and (min-width: 64em) {...} */

/* Largest devices such as desktops (1280px and up) */
/* @media only screen and (min-width: 80em) {...} */
@media (min-width: 757px) {
  #NavlogoMobile {
    display: none;
  }
}

@media only screen and (max-width: 996px) {
  /*FIX ISSUE OF IPAD RESPONSIVENESS*/
  #Navmenudesktop {
    display: none !important;
  }
  .Navlogo {
    display: none !important;
  }
  #NavlogoMobile {
    display: flex !important;
    position: fixed;
    z-index: 9;
    margin: 10px;
  }

  #mobile-menu {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    position: relative;
  }

  #burger-menu {
    cursor: pointer;
    height: 27px;
    width: 35px;
    overflow: visible;
    position: relative;
    z-index: 2;
    background-color: transparent;

    /* // THE LINES */
    span,
    span:before,
    span:after {
      background-color: #fff;
      display: block;
      height: 2px;
      opacity: 1;
      position: absolute;
      transition: 0.3s ease-in-out;
    }

    span:before,
    span:after {
      content: "";
    }

    /* // TOP */
    span:before {
      left: 0px;
      top: -10px;
      width: 35px;
    }

    /* // MIDDLE */
    span {
      left: 0;
      top: 13;
      width: 35px;
    }

    /* // BOTTOM */
    span:after {
      left: 0px;
      top: 10px;
      width: 35px;
    }

    /* // ROTATION ON CLICK */
    &.close {
      /* // TOP */
      span:before {
        top: 0px;
        transform: rotate(90deg);
        width: 35px;
      }

      /* // MIDDLE */
      span {
        transform: rotate(-45deg);
        top: 13px;
        width: 35px;
      }

      /* // BOTTOM */
      span:after {
        top: 0px;
        left: 0;
        transform: rotate(90deg);
        opacity: 0;
        width: 0;
      }
    }
  }

  #menu-content :not(:last-child) {
    padding-bottom: 20px;
  }

  .HoverOverlay2,
  .HoverOverlay3,
  .Overlay2,
  .Overlay3,
  .Overlay1,
  .Rectangle158,
  .Rectangle158,
  .Footercompany,
  .Navmenudesktop {
    display: none !important;
  }

  #HoverOverlay3-mobile,
  #HoverOverlay2-Mobile {
    display: inline-flex !important;
    gap: 20px !important;
    margin-bottom: 20px !important;
    border: "1px solid rgb(140, 132, 132)";
  }

  .Rectangle158 {
    width: 100vw !important;
    height: 25vh !important;
  }

  .ButtonBlog-mobile,
  .ButtonServices-mobile {
    transform: scale(1.2);
  }

  .Footerprivacy {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 0px !important;
    width: 100% !important;
  }

  .Lead {
    max-width: 100% !important;
  }

  .active-menu-mobile {
    background-color: rgba(33, 74, 109, 1) !important;

    .Label {
      color: white !important;
    }
  }

  .Aboutus {
    #about-button {
      transform: translateY(200px) !important;
    }

    .Row {
      flex-direction: column;
      /* gap: 0px; */

      .ContentX,
      .SectionTitle {
        height: auto !important;
      }
    }

    .MySkill {
      height: 100% !important;
      left: 0 !important;
      padding: 20px !important;
      position: relative !important;

      .Row > .last {
        max-width: 100% !important;
      }
    }
  }

  /* .last-form-group {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    } */

  /* .ContactForm {
        background-color: #A2A2C7 !important;
        width: 90% !important;
      }

      .book-consultation{ 
        margin-bottom: 60px !important;
      } */
}

.fade {
  transition: opacity 0.5s ease;
  opacity: 0.5; /* Adjust the opacity value as needed */
}

@media (max-width: 641px) {
  .ContactNav {
    display: block important!;
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;;
  transform: none;
  visibility: visible;
}

@media (min-width: 400px) and (min-height: 800px) {
  .Experience {
    font-size: 20px !important;
  }
  .content-name {
    font-size: 35px !important;
  }
}

@media (max-width: 400px) {
  .BlogContainer {
    height: 305% !important;
  }
}
@media (min-width: 401px) and (max-width: 600px) {
  .BlogContainer {
    height: 300% !important;
  }
}

@media (min-width: 601px) {
  .Bloglogo {
    display: none;
  }
}
.x {
  color: #f0ece7;
}

/* For the Professional Background Cards */
@media (min-width: 576px) and (max-width: 809px) {
  .CardContent {
    height: 300px !important;
    /* min-width: 50vh !important; */
    width: 100% !important;
  }
}
@media (min-width: 810px) and (max-width: 1238px) {
  .CardContent {
    height: 300px !important;
    /* min-height: 50vh !important; */
    width: 100% !important;
  }
}

@media (min-width: 1239px) and (max-width: 1438px) {
  .CardContent {
    height: 350px !important;
    min-width: 45vh !important;
  }
}

@media (min-width: 1439px) {
  .CardContent {
    height: 850px !important;
    min-width: 22vh !important;
    max-width: 40vw !important;
  }
}

/* For the Professional Background Cards */
@media (min-width: 576px) and (max-width: 809px) {
  .CardContent1 {
    height: 300px !important;
    min-width: 50vh !important;
  }
}
@media (min-width: 810px) and (max-width: 1238px) {
  .CardContent1 {
    height: 400px !important;
    min-width: 50vh !important;
  }
}

@media (min-width: 1239px) and (max-width: 1342px) {
  .CardContent1 {
    height: 350px !important;
    min-width: 45vh !important;
  }
}

@media (min-width: 1343px) {
  .CardContent1 {
    height: 450px !important;
    min-width: 22vh !important;
  }
}

/* For the BIO INTRO */

@media (min-width: 576px) and (max-height: 282px) {
  .biointro {
    max-width: 65vw !important;
    max-height: 450px !important;
  }
}

@media (min-width: 576px) and (max-width: 781px) and (min-height: 283px) and (max-height: 349px) {
  .biointro {
    max-width: 50vw !important;
    max-height: 450px !important;
  }
}

@media (min-width: 781px) and (max-width: 916px) and (min-height: 283px) and (max-height: 450px) {
  .biointro {
    max-width: 70vw !important;
    max-height: 450px !important;
  }
}
@media (min-width: 916px) and (max-width: 1500px) and (min-height: 283px) and (max-height: 450px) {
  .biointro {
    max-width: 70vw !important;
    max-height: 550px !important;
  }
  .MyExpertise {
    margin-top: 100px;
  }
}

@media (min-width: 576px) and (max-width: 980px) and (min-height: 451px) {
  .biointro {
    max-width: 33vw !important;
  }
}

@media (min-width: 980px) and (max-width: 1200px) and (min-height: 451px) {
  .biointro {
    max-width: 38vw !important;
  }
}

@media (min-width: 1201px) and (max-width: 1300px) and (min-height: 451px) {
  .biointro {
    max-width: 47vw !important;
  }
}
@media (min-width: 1301px) and (min-height: 451px) {
  .biointro {
    max-width: 47vw !important;
  }
}

/*LOVE LA PAGE*/
.contentContainer {
  margin-bottom: 50px;
}

.BlogContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: white;
}

#NavlogoMobile img {
  height: 7vh;
  margin-top: 0vh;
  position: fixed;
  left: 10px;
  top: 10px;
  cursor: pointer;
}

.Banner img {
  z-index: 2;
  width: 100vw;
  max-height: 200px;
  margin-top: 3vh;
  object-fit: cover;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
}

.MySkill {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 56.67px;
  display: inline-flex;
  padding: 0px 3vw;
}

.SectionTitle {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3vh;
  display: flex;
}

.Content {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  display: flex;
}

.MyExpertise {
  align-self: stretch;
  color: #282938;
  font-size: clamp(40px, 4vw, 61px);
  font-family: "Playfair Display", serif;
  font-weight: 700;
  word-wrap: break-word;
}

.section {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.section:nth-child(even) {
  flex-direction: row-reverse;
}

.sectionImage {
  width: 40%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.sectionText {
  width: 60%;
}

.sectionText h2 {
  color: #282938;
  font-size: clamp(24px, 3vw, 30px);
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.sectionText p,
.sectionText ul {
  color: black;
  font-size: clamp(14px, 2vw, 17px);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.sectionText ul {
  padding-left: 20px;
}

.sectionText ul li {
  list-style-type: disc;
  margin-bottom: 10px;
}

/* Mobile view */
@media (max-width: 768px) {
  .section {
    flex-direction: column;
    text-align: center;
  }

  .section:nth-child(even) {
    flex-direction: column;
  }

  .sectionImage,
  .sectionText {
    width: 100%;
  }
  .coachingbull {
    text-align: start;
  }
}

/* LOVE LAB DROPDOWN */
.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adjust spacing between buttons */
  max-width:600px;
}

.infoButton {
  background-color: rgb(14,48,34); /* Button background color */
  color: #ffffff !important; /* Button text color */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.infoButton:hover {
  background-color: rgb(28, 95, 67); /* Hover background color */
  scale: 1.1;
  color:white !important;
}

@media (max-width:800px){
  .section{
    max-width:85vw
  }
  .sectionImage{
    max-width:85vw
  }
}

/*LOADING SCREEN*/

/* Pulsing Heart Animation */
@keyframes pulseheart {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.pulsing-heart {
  position: absolute;
  width: 300px;
  height: 300px;
  background: url('../public/heartwlogo.png') no-repeat center center;
  background-size: contain;
  animation: pulseheart 3.3s infinite;
  opacity: 0; /* Initially hidden */
  transition: opacity 2s ease-in-out; /* Smooth fade-in and fade-out */
  z-index: 2; /* Ensures it is on top of the video */
}

.heart-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1; /* Ensures the video is behind the heart */
}

@keyframes pulseheart {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  25% {
    transform: scale(1);
    opacity: 0.5;
  }
  75% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
  transition: opacity 1s ease, visibility 1s ease;
}

.loading-screen.fade-out {
  animation: fadeOut 1s forwards; /* Duration of the fade-out */
}

.portrait {
  display: none; /* Hide portrait video by default */
}

.landscape {
  display: none; /* Hide landscape video by default */
}

@media (orientation: portrait) {
  .portrait {
    display: block; /* Show portrait video in portrait mode */
  }
}

@media (orientation: landscape) {
  .landscape {
    display: block; /* Show landscape video in landscape mode */
  }
}





/*STRIPE*/

.checkout-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.card-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.pay-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pay-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.error-message {
  color: red;
  font-size: 14px;
}

/* Smooth transitions for sections */
.fade-in {
  opacity: 0;
  transition: opacity 0.6s ease;
}

.fade-in.visible {
  opacity: 1;
}

/* Button styles */
.button {
  background-color: #007bff;
  color: #fff !important;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: black;
  color:white;
}

/* Section styles */
.section-content {
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  line-height: 1.6;
  transition: max-height 0.6s ease, opacity 0.6s ease;
  overflow: hidden;
}

.section-content.hidden {
  max-height: 0;
  opacity: 0;
}

.section-content.visible {
  /* max-height: 500px; */
  opacity: 1;
}

